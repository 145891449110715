import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const BudgetingPage = () => {
    const article = {
        id: '53129841-47ea-547f-b1de-9ffc6c50d681',
        title: 'The Ultimate Guide to Budgeting',
        slug: '/budgeting/',
        date: '2018-01-24T14:28:01.000Z',
        modified: '2021-11-04T18:43:22.000Z',
        excerpt: 'Budgeting is something that many people dread even thinking about, let alone actually doing. With this helpful guide, we&#8217;ll explore the basics of a good budget and the easiest ways to stick with it.',
        featured_image: {
            source_url: '/media/tips-for-sticking-to-a-budget.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 8,
        content: (
            <>
                <p>Spending money is easy.</p>
                <p>Paying it back when the bill arrives is the hard part. </p>
                <p>
                    Statistics show that Americans
                    {' '}
                    <a target="_blank" href="http://www.foxbusiness.com/features/2017/09/09/this-is-how-much-credit-card-debt-average-american-holds.html" rel="noreferrer">have an average household credit card debt of $16,425</a>
                    {' '}
                    —a 10% increase since 2013.
                    {' '}
                </p>
                <p>Overspending is usually to blame, especially on things like entertainment, high mortgages, and retail items. </p>
                <p>Here are three steps you can take to reduce your spending habits and begin budgeting more effectively. </p>
                <h2 id="understand-why-you-overspend">Understand why you overspend</h2>
                <p>Take a close, honest look at why you might be overspending. </p>
                <p>Spending more than you can afford is often a knee-jerk reaction to boredom, stress, low self-esteem and other unpleasant feelings. </p>
                <p>One way to scratch the itch is to include more fun in your life – frugal fun. </p>
                <p>
                    Instead of spending money you don&rsquo;t have on stuff you probably don&rsquo;t need, spend more time with family and friends and participate in activities that will bring true joy to your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=budgeting&amp;sub2=life" rel="noopener noreferrer" target="_blank">life</a>
                    .
                    {' '}
                </p>
                <p><LazyLoadImage src="/media/average-consumer-spending-by-year-1.png" alt="Average Consumer Spending by Year" /></p>
                <p>Sounds simple, but research continually shows that the happiness and satisfaction felt from making a material purchase pales in comparison to the positive feelings a little low-cost, meaningful fun can provide. </p>
                <p>Give it a try.</p>
                <p>
                    Another way to escape the lure of overspending is to
                    {' '}
                    <a href="https://www.debtconsolidation.com/14-invaluable-habits/">make a habit</a>
                    {' '}
                    out of being able to identify the difference between wants and needs.
                    {' '}
                </p>
                <p>If you want to find out what&rsquo;s driving all the money out of your wallet, sit down and make a need versus want list; after all, wants can feel a lot like needs. </p>
                <p>Transportation and clothing are essential needs but keeping a high-end vehicle in the garage and wearing last year&rsquo;s designer sweaters probably fall into the want category – especially if you&rsquo;re still paying for them.</p>
                <p>
                    When you do shop, leave the
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=budgeting&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    at home, and pay with cash.
                    {' '}
                </p>
                <p>It&rsquo;s easier to overspend with plastic. </p>
                <p>Real money in your hand makes overspending less likely. </p>
                <p>If a want still bends your ear, try delaying the purchase. </p>
                <p>Experts say the urge to splurge is likely to pass if you just wait 10 minutes. </p>
                <p>
                    And if online shopping is your inclination, try this&nbsp;
                    <a target="_blank" href="http://resources.depaul.edu/financial-fitness/tackle-overspending/Pages/solutions-for-overspending.aspx" rel="noreferrer">quirky tip from DePaul University</a>
                    : Freeze your credit cards in a glass of water the next time you feel tempted to spend.
                    {' '}
                </p>
                <p>By the time they thaw, you might feel differently about how badly you &quot;need&quot; the item. </p>
                <p>It&rsquo;s also a good idea not to keep your credit card information on record with retailers.</p>
                <h3>Overspending on necessities</h3>
                <p>
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=budgeting&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">Credit cards</a>
                    {' '}
                    are useful for buying what you need when cash flow is low – things like auto and home repairs or even pleasurable stuff like vacations or gifts that you know you can comfortably pay off in a reasonable amount of time (90 days or less).
                    {' '}
                </p>
                <p>
                    On the other hand, if you&rsquo;re reaching for
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=budgeting&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    to purchase everyday living essentials like groceries, gas and other items,
                    {' '}
                    <a href="https://www.debtconsolidation.com/credit-card-debt/">yet still have trouble paying for them when the bill arrives</a>
                    , getting some&nbsp;
                    <a href="https://www.debtconsolidation.com/credit-counseling/">professional advice&nbsp;might be a good idea</a>
                    .
                </p>
                <h2 id="make-the-switch-to-a-credit-union">Make the switch to a credit union</h2>
                <p>When families find themselves struggling to pay the all the bills, the need to slash monthly expenses becomes even more important. </p>
                <p>
                    In addition to paring back spending and paying down debts,
                    {' '}
                    <a href="https://www.creditloan.com/blog/easy-budgeting-tips-that-save-money/">smart consumers can stretch the monthly budget further</a>
                    {' '}
                    by switching banking services to a not-for-profit credit union.
                </p>
                <h3>About Credit Unions</h3>
                <p>Credit unions are member-owned financial cooperatives and offer virtually the same products and services as most banks. </p>
                <p>
                    Revenue earned goes back to the members in the form of higher rates on
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=budgeting&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    , lower rates on loans, low- or no-fee products and much better deals on some insurance offerings, such as automobile asset protection coverage.
                </p>
                <p>Credit unions own about 7% of the banking market in the U.S. but their appeal is growing as more consumers gain awareness. </p>
                <p>
                    According to&nbsp;
                    <a target="_blank" href="http://www.businessinsider.com/credit-unions-vs-big-banks-2013-4" rel="noreferrer">Business Insider</a>
                    , in the last year alone, the credit union industry surpassed $1 trillion in assets, adding more than 2 million new members.
                </p>
                <p><LazyLoadImage src="/media/us-credit-unions-2.png" alt="US Credit Unions" /></p>
                <h3>How much can I save?</h3>
                <p>
                    Start with basic checking. According to&nbsp;
                    <a target="_blank" href="http://www.bankrate.com/" rel="noreferrer">Bankrate.com</a>
                    , more than 70% of the largest credit unions offer free checking, compared to just 39% of banks.
                    {' '}
                </p>
                <p>At an average cost of just over $12 a month for regular checking at banks, that&rsquo;s $150 in immediate annual savings. </p>
                <p>For fee-based accounts, the requirements to avoid a fee are typically easier to meet at credit unions than at banks.</p>
                <p>Overdraft fees are a big money-maker, and average over $30 at banks while the same fee at credit unions can be as low as $12 and averages in the $20s.&nbsp;</p>
                <p>
                    <a href="https://www.bankofamerica.com/deposits/resources/personal-schedule-fees.go">Bank of America</a>
&nbsp;for example, charges $35.&nbsp;
                </p>
                <p>SunTrust&nbsp;charges a whopping $36 per overdraft.</p>
                <p>Credit unions are known for making affordable car loans. </p>
                <p>
                    A quick inquiry shows that&nbsp;
                    <a target="_blank" href="http://www.sccu.com/" rel="noreferrer">Space Coast Credit Union</a>
&nbsp;of Florida, one of the state&rsquo;s largest, offers rates as low as 1.89% APR on new and used cars as old as 2007 models.
                    {' '}
                </p>
                <p>A $20,000 used car loan at 1.89% at 48 months costs $433 a month with Space Coast. </p>
                <p>
                    A bank car loan at&nbsp;
                    <a href="https://www.wellsfargo.com/auto-loans/">Wells Fargo</a>
&nbsp;in the same area will cost $458 a month at their best rate of 4.74%.
                    {' '}
                </p>
                <p>That&rsquo;s another $300 a year in your pocket with the credit union loan.</p>
                <p>Paying bills online is convenient, but the wrong account can cost you. </p>
                <p>At Wells Fargo, unless you keep a minimum of $5,000 on deposit in your personal checking account, Bill Payer costs $36 a year for up to 25 payments a month. </p>
                <p>
                    At&nbsp;
                    <a href="https://www.ccuflorida.org/">Community Credit Union</a>
&nbsp;in Melbourne, Fla,, similar to most other credit unions, it&rsquo;s free with unlimited payments.
                </p>
                <p>Car dealers typically charge $750 or more for guaranteed asset protection insurance but your credit union may offer the same coverage for about half. </p>
                <p>
                    That&rsquo;s another $85 a year
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=budgeting&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    on a four-year loan.
                </p>
                <p>So, with just a small shift to a credit union, you can easily rack up about $570 a year savings in ordinary banking and loan services and we&rsquo;ve just scratched the surface. </p>
                <p>
                    According to the&nbsp;
                    <a target="_blank" href="http://www.cuna.org/" rel="noreferrer">Credit Union National Association</a>
&nbsp;Policy Analysis, credit union members in total enjoyed $5.8 billion in savings compared to bank customers in 2012.
                </p>
                <h3>Where to learn more</h3>
                <p>
                    &quot;You may be able to join a credit union based on where you live, where you work, where you worship, or your membership in an association&quot; recommends&nbsp;
                    <a target="_blank" href="http://www.mycreditunion.gov/about-credit-unions/Pages/default.aspx" rel="noreferrer">MyCreditUnion.gov</a>
                    .&nbsp;
                </p>
                <p>Formerly structured as closed memberships, many credit unions today are open to the public in specified geographic areas. </p>
                <p>
                    To find a credit union near you click on&nbsp;
                    <a target="_blank" href="http://www.asmarterchoice.org/" rel="noreferrer">ASmarterChoice.org</a>
&nbsp;or&nbsp;
                    <a target="_blank" href="http://www.mycreditunion.gov/pages/mcu-map.aspx" rel="noreferrer">Credit Union Locator</a>
                    .
                </p>
                <h2 id="use-a-debt-calculator-to-your-advantage">Use a debt calculator to your advantage</h2>
                <p>If you intend to eliminate your debt, one of the first steps is also one of the hardest: figuring out exactly how much you owe and what it will take to pay if off. </p>
                <p>Credit card companies have a perfect formula for keeping their customers on the hook as long as possible, providing a minimum required payment that ensures that it will take years to pay your balance in full.</p>
                <p>
                    Thanks to the&nbsp;
                    <a target="_blank" href="http://www.consumerfinance.gov/credit-cards/credit-card-act/feb2011-factsheet/" rel="noreferrer">CARD Act</a>
                    , your credit card statement now shows you how much you must pay to reduce your balance to zero within three years; how long it will take you to pay off your credit card if you only make the minimum payment and how much interest you have paid on the balance each year.
                </p>
                <p>
                    While those pieces of information are helpful, you may want to use a&nbsp;
                    <a target="_blank" href="http://www.bankrate.com/calculators/credit-cards/credit-card-payoff-calculator.aspx" rel="noreferrer">debt calculator</a>
&nbsp;to get a better handle on how to get yourself out of debt.
                    {' '}
                </p>
                <p>Gather your most recent credit card statements to get started. </p>
                <p>
                    First, though, take the pledge to&nbsp;
                    <a href="https://www.nfcc.org/tools-and-education/money-management-tips/preventing-debt-paralysis/">stop using your credit</a>
&nbsp;cards immediately.
                </p>
                <h3>Three ways to use a debt calculator</h3>
                <p>A debt calculator can be used in several ways to set up your repayment plan.</p>
                <h4>1.&nbsp;Estimate your debt payoff date.</h4>
                <p>Your first calculation should be to take each credit card bill and enter the current balance, the interest rate and the amount of money you typically pay on your bill. </p>
                <p>Hopefully, you&rsquo;re already paying more than the minimum due, but, either way, you need to know how long it will take to pay off each card. </p>
                <p>You can also add up all your credit card balances and average your interest rates and monthly payments to see when you will be debt-free with your current repayment plan.</p>
                <h4>2.&nbsp;Evaluate the impact of above-minimum payments.</h4>
                <p> As part of the process of a do-it-yourself debt reduction plan, you should establish a budget. </p>
                <p>Look for ways to reduce your spending or increase your income so that you&rsquo;ll have extra cash to use to reduce your credit card balance. </p>
                <p><LazyLoadImage src="/media/average-household-income-3.png" alt="Average Household income" /></p>
                <p>Next, use your debt calculator to see the impact of extra payments on your credit card bill. </p>
                <p>For example, if you have a $5,000 credit card balance at 17.5% and you currently pay $100 per month, it will take 90 months to pay in full. </p>
                <p>If you add $50 to your payment each month, you can cut the payoff time nearly in half to 46 months. </p>
                <p>Experiment with different amounts to see the impact of as little as $20 more per month on your repayment plan. </p>
                <p>In this scenario, you could pay your bill in full 25 months faster by increasing your payment as little as $20.</p>
                <h4>3.&nbsp;Calculate a payment to reach your preferred payoff date.</h4>
                <p>An alternative way to use a debt calculator is to choose a specific date to become debt-free. </p>
                <p>This could be before a child starts college, before you retire, or just within two or three years. </p>
                <p>You can plug in a date to the calculator and it will generate the payment required to reach your goal. </p>
                <p>For instance, if you want to pay off that $5,000 balance in the above example within three years, you would need to pay $179.51 each month. </p>
                <p>If you can&rsquo;t afford that, you can shift the date to 3 1/2 years and pay $160.04 each month.</p>
                <p>
                    The credit counseling experts at the&nbsp;
                    <a target="_blank" href="http://www.nfcc.org/" rel="noreferrer">National Foundation for Credit Counseling</a>
&nbsp;recommend paying at least double the minimum monthly payment on your credit cards, but if you can&rsquo;t do that, a calculator can help you set an appropriate goal for your repayment plan.
                </p>
                <h2 id="get-a-budget-that-works">Get a budget that works</h2>
                <p>Budgets should be one thing above all else: simple.</p>
                <p>An overly complicated budget is harder to stick with than one that is easy to glance at and understand. </p>
                <p>
                    Going beyond a budget may be necessary if you&rsquo;re looking to get out of debt –
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-management/">that&rsquo;s where a debt management plan might come in handy</a>
                    .
                </p>
                <p>While there is no magic bullet to help you stop spending less and take the pilot&rsquo;s seat with regard to your finances, the three steps above—used in conjunction with budgeting—can help you get back on your feet. </p>

            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default BudgetingPage;
